import store from '../../../global-state/redux';
import { apiManageCaller } from '../../../global-utils/vmFunctions/vmApi/apiUtils';
import { setError, setLoading, setReady } from '../model/slice';
import { setMembership } from '../model/state';

const { dispatch } = store;

export const fetchMembershipOverview = async (moduleSettigs) => {
  const { societyShort, endpoint } = moduleSettigs ?? {};
  return apiManageCaller({
    replaceConfig: {
      pShort: societyShort
    },
    endpoint,
    addToParams: {
      useShortName: true
    }
  });
};

export const handleShowInDirectory = async (moduleSettigs, checked) => {
  const { societyShort, showInDirectoryPostEndpoint } = moduleSettigs ?? {};
  dispatch(setLoading);
  return apiManageCaller({
    replaceConfig: {
      pShort: societyShort
    },
    endpoint: showInDirectoryPostEndpoint,
    addToParams: {
      useSocietyShort: true
    },
    body: {
      ShowInDirectory: !checked
    },
    method: 'POST'
  }).then(() => fetchMembershipOverview(moduleSettigs))
    .then(setMembership)
    .then(() => dispatch(setReady))
    .catch((err) => {
      console.error('Error in fetch membership', err);
      dispatch(setError);
    });
};
