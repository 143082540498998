import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import ComponentsArrRenderer from '../core/ComponentsArrRenderer';
import {
  apiDataInfoPropType,
  componentsPropType,
  stylesPropType,
  vmPropTypes
} from '../../global-prop-types';
import { mapData } from '../../global-utils/dataMapping';
import vmFunctions from '../../global-utils/vmFunctions';
import { isArr, isObj, vmTreeSet } from '../../global-utils';
import store from '../../global-state/redux';
import { Loading } from '../global';

const { getState } = store;

// states used by debouncers:
const READY = 'READY';
const FETCHING = 'FETCHING';
const DEBOUNCING = 'DEBOUNCING';

// DRY - common part of fetch process.
// This fetch fuction exists so we do not use our common useApiDataHook. This
// is because to monitor the API call state, API data hook updates the state and we get
// into ifinite loop. Also, this component can manage himself without subscribing to the
// stor or dispatching actions. fetch2 returns promis, coz we have to perform different actions
// when we make first call on component render vs call we make when we scroll to the bottom.
const fetch2 = async (apiDataInfo) => {
  // since apiDataInfo comes from layoutSettings, we need to be ready for anything:
  // we do not stop application from running, but show no data:
  if (!isObj(apiDataInfo)) return Promise.resolve({ data: [], isReady: true });

  const { serverAddr, url: pathname, body } = apiDataInfo;
  const { core } = getState().appState;

  const urlSearchParams = new URLSearchParams(body).toString();
  const url = new URL(`https://${serverAddr ?? core.server_addr}`);
  url.search = urlSearchParams;
  url.pathname = pathname;
  const headers = {
    Authorization: `Basic ${btoa('m-events:kims')}`,
    'cache-control': 'no-cache'
  };

  return fetch(url, { headers })
    .then((res) => res.json().then((data) => ({
      headers: Object.fromEntries(res.headers),
      data,
      isReady: true // is ready is what allows for makeing API cals o scroll.
    })))
    .catch(() => Promise.resolve({
      data: [],
      isReady: false // setting it false, prevents makeing API calls on scroll.
    }));
};

const InfiniteScrollPage = (props) => {
  const {
    apiDataInfo, // only first element of the array is taken into account.
    components,
    styles,
    variant,
    debounceIntervalMainApiCall = 1000,
    debounceIntervalScrollApiCall = 4000,
    scrollApiTrigger = 1,
    apiData: apiDataProps,
    ...restProps
  } = props;
  const [apiData, setApiData] = useState(null); // holds response fro API calls
  const boxRef = useRef();
  const refScrollDirection = useRef(0); // used to detect direction of scrolling
  const refMainApiCall = useRef(null); // used as a LOCK when makeing main API calls
  const refScrollApiCall = useRef(READY); // used as a LOCK when makeing API calls on scroll events
  const refScrollDebouncer = useRef(null); // used as a LOCK when makeing API calls on scroll events

  // Because the apiDataInfo is just a JSON, it does not triger rerender when apped values change.
  // So first we have to map apiDataInfo to see if there is change in API call parameters:
  const dataBank = { props: restProps, vmFunctions };
  const apiDataInfoMapped = mapData(dataBank, apiDataInfo?.[0] ?? {}); // resolve data mapping:

  // If string in dependency array triggered useEffect, that means we have new values inapiDataInfo from props.
  // It is important not to set apiDataInfo state on every render, that's why we use debouncer here.
  // Each time some parameters change, we postpone setting apiDataInfo state by refreshRate:
  useEffect(() => {
    // set the debouncer:
    const timerId = setTimeout(() => {
      // refMainApiCall holds current api call id (in a form of timerId).
      // It can happen, that a new api call needs to be dispatched while the old one did not finish
      // Then we shell not use data from the first call to update the list, bt alredy use data from teh second call.
      // For this reasons we setApiData only when refMainApiCall agrees with the timerId:
      refMainApiCall.current = timerId;

      if (!Object.values(apiDataInfoMapped)?.length) return;

      fetch2(apiDataInfoMapped).then((res) => {
        if (
          timerId === refMainApiCall.current // this assures, that we update the most recent call results.
        ) {
          setApiData(res);
          refMainApiCall.current = READY;
        }
      });
    }, debounceIntervalMainApiCall);
    refMainApiCall.current = DEBOUNCING;

    return () => clearTimeout(timerId);
  }, [JSON.stringify(apiDataInfoMapped)]);

  // useEffect responsible for making API call on scroll. The threshould when to trigger
  // API call depends on apiData returned from the API call made on changes to apidataInfo.
  // it sets an event listener on `scroll` and listend if we are on the bottom of a box.
  useEffect(() => {
    const xNextPage = apiData?.headers?.['x-next-page']; // when there are no more pages it is undefined.

    // conditions for which it doesn't make sens to set scroll event listener:
    if (!boxRef.current || refMainApiCall.current !== READY || !xNextPage) {
      return () => null;
    }

    const handleScroll = () => {
      const clientRectBottom = boxRef?.current?.getBoundingClientRect().bottom;

      // dH > 0 - there is still space to scroll
      // dH = 0 - means the bottom part of the div is exacly at the bottom part of the
      //   view port
      // dH <= 0 - the bottom part of the div above the windoes bottom edge
      const dH = clientRectBottom - window.innerHeight;

      // dScrollTop - negitive value means we scroll down, positive we scroll up:
      const scrollDirection = clientRectBottom - refScrollDirection.current;
      refScrollDirection.current = clientRectBottom; // save current position in ref. We do not need to triger render on this prop.

      // condition to make API call:
      const shouldMakeApiCall = dH <= scrollApiTrigger
        && scrollDirection < 0 // call only when we scroll down
        && refScrollApiCall.current === READY // call only when previous API finished
        && !refScrollDebouncer.current; // dont call when debounce interval didn't pass

      if (shouldMakeApiCall) {
        // add nex page to API call:
        const apiDataInfoNew = vmTreeSet(
          apiDataInfoMapped,
          ['body', 'page'],
          xNextPage
        );

        // set debouncer that blocks all subsequent requests to make new API calls:
        clearTimeout(refScrollDebouncer.current);
        refScrollDebouncer.current = setTimeout(() => {
          refScrollDebouncer.current = null;
        }, debounceIntervalScrollApiCall);

        // set lock for API call:
        refScrollApiCall.current = FETCHING;

        // after both locks are set, make API call:
        fetch2(apiDataInfoNew).then((res) => {
          // After we have response, we shell combine data arrays:
          const dataOld = apiData?.data ?? [];
          const dataRes = isArr(res.data) ? res.data : []; // res.data does not always is an array
          const dataNew = [...dataOld, ...dataRes];
          const apiDataNew = {
            ...res,
            data: dataNew
          };
          setApiData(apiDataNew);
          refScrollApiCall.current = READY;
        });
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [
    boxRef.current,
    apiData, // for new apiData we get new xNextPage value
    refMainApiCall.current // listen to main API call status
  ]);

  // append infinite scroll apiData to the usuall object of apiData:
  const { uiTitle = 'infiniteScrollData' } = apiDataInfoMapped ?? {};
  const apiDataNew = {
    ...apiDataProps,
    [uiTitle]: apiData
  };

  if (!Object.values(apiDataInfoMapped)?.length) return null;
  if (!apiDataNew?.[uiTitle]?.isReady) return <Loading />;

  return (
    <div className={`${variant ?? ''}`} style={styles?.wrapper} ref={boxRef}>
      <ComponentsArrRenderer
        components={components}
        apiData={apiDataNew}
        {...restProps}
      />
    </div>
  );
};

InfiniteScrollPage.propTypes = {
  variant: PropTypes.string,
  styles: stylesPropType,
  apiData: PropTypes.shape({}),
  apiDataInfo: apiDataInfoPropType,
  components: componentsPropType,
  debounceIntervalMainApiCall: PropTypes.number,
  debounceIntervalScrollApiCall: PropTypes.number,
  scrollApiTrigger: PropTypes.number
};

InfiniteScrollPage.vmPropTypes = {
  variant: vmPropTypes.className,
  components: vmPropTypes.components,
  debounceIntervalMainApiCall: vmPropTypes.number,
  debounceIntervalScrollApiCall: vmPropTypes.number,
  scrollApiTrigger: vmPropTypes.number
};

export default InfiniteScrollPage;
